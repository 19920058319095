@use '@angular/material' as mat;

@include mat.core();
// @include mat.legacy-core();

@import './globals.scss';
@import './arcus-theme.scss';
@import '@angular/material/theming';
@import './material-scheme/material-scheme.scss';
@import './material-scheme/inputs.scss';
@import './material-scheme/snackbar.scss';
@import './lib-styles.scss'; //? Does this need to be moved/removed?
@import './parts.scss'; //? Does this need to be moved/removed?

$app-typography: mat.define-typography-config(
  $font-family: '"open sans", sans-serif',
  $headline-1: mat.define-typography-level(112px, 112px, 300),
  $headline-2: mat.define-typography-level(56px, 56px, 400),
  $headline-3: mat.define-typography-level(45px, 48px, 400),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $headline-6: mat.define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400),
  $subtitle-2: mat.define-typography-level(15px, 24px, 400),
  $body-1:   mat.define-typography-level(14px, 24px, 500),
  $body-2:   mat.define-typography-level(14px, 20px, 400),
  $caption:  mat.define-typography-level(12px, 20px, 400),
  $button:   mat.define-typography-level(14px, 14px, 500),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$arcus-app-primary: mat.define-palette($arcus-primary);
$arcus-app-accent: mat.define-palette($arcus-border, A200, A100, A400);

// The warn palette is optional (defaults to red).
$arcus-app-warn: mat.define-palette($arcus-warning);

// Create the theme object (a Sass map containing all of the palettes).
$arcus-app-theme: mat.define-light-theme((
  color: (
    primary: $arcus-app-primary,
    accent: $arcus-app-accent,
    warn: $arcus-app-warn
  ),
  typography: $app-typography
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixing for each component
// that you are using.
@include mat.all-component-themes($arcus-app-theme);
@include mat.all-component-typographies($app-typography);

// * {
//   font-family: 'Open Sans', sans-serif; //? Investigate why this was !important previously
// }

@media print {
  * { overflow: visible !important; }
}

html,
body {
  height: 100%;
  background-color: #f6f6f6;
  color: #07192C;
}

body {
  margin: 0;
}

/* Material overrides START */

.mat-mdc-menu-panel.header__mobile-menu {
  display: none;

  @media only screen and (max-width: 970px) {
    display: block;
  }

  &.header__mobile-menu .mat-mdc-menu-item.header__tab--active {
    background-color: lighten($button-colour, 43%);
  }
}
.mat-mdc-paginator-container{
  justify-content: center!important;
}

.remove-parts-dialog mat-dialog-container{
  background-color: #fdfcf8;
}
/* parts list discount adornment */
.discAdornment {
  background-color: transparent !important;
}
app-paginator {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

//TODO: Remove this and add to component lib
.tla-slider-outer__optimum-overlay-icon {
  top: 11px !important;
}

// APP 'fixes' from comp lib
.app-slider__active-bar {
  bottom: 6px !important;
}

.app-slider__handle {
  bottom: -2px !important;
}

.cdk-overlay-pane--rounded {
  .mdc-dialog__surface {
    border-radius: 16px !important;
    padding: 30px !important;
  }
}

.notifications-filter-list {
  .expander--expanded {
    .expander__body {
      overflow-y: auto;
      padding-left: 0.6rem;
      margin-left: -0.6rem;
      margin-right: -.4rem;
    }
  }
}
